<template>
  <div class="collectFileList">
    <div class="collectFileItem addFile" @click="showPop(null)">
      <img src="@/assets/image/icon/addBank-icon.png" alt="" />
      <p class="mt20 f16 c_99">新建文件夹</p>
    </div>
    <div
      @click="goLink(item)"
      class="collectFileItem"
      v-for="(item, index) in folderList"
      :key="index"
    >
      <div class="fileItemImg">
        <div class="itemSet">
          <i class="el-icon-more f22 c_33"></i>
          <div class="setProp">
            <p class="active" @click.stop="showPop(item)">编辑文件夹</p>
            <p @click.stop="delFolder(item.id,item.num)">删除</p>
          </div>
        </div>
        <div class="itemImgsBox">
          <div class="noFilesImg">
            <img class="img1" v-if="item.img[0]" :src="item.img[0]" alt="" />
            <img
              v-else
              class="img"
              src="@/assets/image/icon/noFiles-icon.png"
              alt=""
            />
          </div>
          <div class="noFilesImg">
            <img class="img1" v-if="item.img[1]" :src="item.img[1]" alt="" />
            <img
              v-else
              class="img"
              src="@/assets/image/icon/noFiles-icon.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="mt10">
        <p class="f16 f0404">{{ item.name }}</p>
        <div class="df_Yc mt10">
          <i class="iconfont f20 c_99 mr10">&#xe788;</i>
          <p class="c_33 f14">{{ item.num }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CollectMaterialList",
  props: ["folderList"],
  data() {
    return {};
  },
  methods: {
    goLink(data) {
      this.$router.push({
        path: "myCollectDetail",
        query: { detailId: data.id, type: "max", name: data.name },
      });
    },
    // 显示弹窗
    showPop(data) {
      this.$emit("showPopFun", data);
    },
    delFolder(id,num) {
      if (num > 0) {
        this.$message.error("该文件夹中还有内容，请先清空文件夹再删除");
      } else {
        this.$emit("delFolder", id);
      }
    },
  },
};
</script>
<style scoped>
.noFilesImg .img {
  width: 0.26rem;
  height: 0.26rem;
}
.noFilesImg .img1 {
  width: 100%;
}
.noFilesImg {
  width: 1.15rem;
  height: 1.15rem;
  border-radius: 0.04rem;
  overflow: hidden;
  background: #f8f8fb;
  display: flex;
  justify-content: center;
  align-items: center;
}
.itemImgsBox {
  display: flex;
  justify-content: space-between;
}
.imgItem {
  width: 1.15rem;
  height: 1.15rem;
  border-radius: 0.04rem;
  overflow: hidden;
  background: #f8f8fb;
}
.collectFileList >>> .el-button--primary {
  background-color: #f46600;
  border-color: #f46600;
}
.collectFileList >>> .el-dialog {
  width: 4.4rem;
}
.setProp {
  width: 1.1rem;
  border-radius: 0.02rem;
  background: #ffffff;
  padding-top: 0.08rem;
  position: absolute;
  right: 0;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.itemSet:hover .setProp {
  opacity: 1;
  visibility: visible;
}
.setProp p:hover {
  background: #fff4e0;
}
.setProp p {
  text-align: center;
  line-height: 0.36rem;
  font-size: 0.14rem;
}
.itemSet {
  position: absolute;
  top: 0.05rem;
  right: 0.1rem;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 0;
}
.fileItemImg:hover .itemSet {
  opacity: 1;
  visibility: visible;
  z-index: 10;
}
.addFile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0.01rem dashed #bfbfbfbf;
  background: #fcfcfe !important;
}
.addFile img {
  width: 0.5rem;
  height: 0.5rem;
}
.collectFileList {
  margin-right: -0.2rem;
  margin-top: 0.3rem;
}
.collectFileList::after {
  content: "";
  height: 0;
  width: 0;
  display: block;
  visibility: hidden;
  clear: both;
}
.fileItemImg {
  width: 100%;
  height: 1.15rem;
  border-radius: 0.04rem;
  overflow: hidden;
  position: relative;
}
.collectFileItem {
  margin-right: 0.05rem;
  float: left;
  width: 23.85%;
  height: 2.02rem;
  background: #ffffff;
  border-radius: 0.04rem;
  padding: 0.1rem 0.1rem 0.15rem 0.1rem;
  margin-bottom: 00.2rem;
  cursor: pointer;
  margin-right: 0.1rem;
}
</style>