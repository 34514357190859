import { render, staticRenderFns } from "./CollectCourseList.vue?vue&type=template&id=33ae8f35&scoped=true&"
import script from "./CollectCourseList.vue?vue&type=script&lang=js&"
export * from "./CollectCourseList.vue?vue&type=script&lang=js&"
import style0 from "./CollectCourseList.vue?vue&type=style&index=0&id=33ae8f35&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33ae8f35",
  null
  
)

export default component.exports