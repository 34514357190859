<template>
  <div>
    <div class="seleteBox">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="课程" name="6" lazy></el-tab-pane>
        <el-tab-pane label="SU模型" name="561" lazy></el-tab-pane>
        <el-tab-pane label="3D模型" name="1393" lazy></el-tab-pane>
        <el-tab-pane label="PS素材" name="1196" lazy></el-tab-pane>
        <el-tab-pane label="方案文本" name="1394" lazy></el-tab-pane>
        <el-tab-pane label="资料库" name="7" lazy></el-tab-pane>
        <el-tab-pane label="图库" name="2381" lazy></el-tab-pane>
        <el-tab-pane label="贴图" name="2205" lazy></el-tab-pane>
      </el-tabs>
      <div>
        <el-input
          v-model="searchKey"
          placeholder="请输入内容"
          @keyup.enter.native="search"
        >
          <i class="iconfont el-icon-search c_p" slot="suffix" @click="search"
            >&#xe67d;</i
          >
        </el-input>
      </div>
    </div>
    <CollectSuList
      @showPopFun="showPopFun"
      :folderList="folderList"
      @delFolder="delFolder"
      v-if="activeName == '561'"
    ></CollectSuList>
    <CollectCourseList
      @showPopFun="showPopFun"
      @delFolder="delFolder"
      :folderList="folderList"
      v-if="activeName == '6'"
    ></CollectCourseList>
    <CollectMaterialList
      @showPopFun="showPopFun"
      @delFolder="delFolder"
      :folderList="folderList"
      v-if="activeName == '7'"
    ></CollectMaterialList>
    <CollectPhotoList
      @showPopFun="showPopFun"
      @delFolder="delFolder"
      :folderList="folderList"
      v-if="activeName == '2381'"
    ></CollectPhotoList>
		<CollectChartletList
			@showPopFun="showPopFun"
			@delFolder="delFolder"
			:folderList="folderList"
			v-if="activeName == '2205'"
		></CollectChartletList>
    <CollectPsList
      @showPopFun="showPopFun"
      @delFolder="delFolder"
      :folderList="folderList"
      v-if="activeName == '1196'"
    ></CollectPsList>
    <CollectMaxList
        @showPopFun="showPopFun"
        @delFolder="delFolder"
        :folderList="folderList"
        v-if="activeName == '1393'"
    ></CollectMaxList>
    <CollectSchemeTextList
        @showPopFun="showPopFun"
        @delFolder="delFolder"
        :folderList="folderList"
        v-if="activeName == '1394'"
    ></CollectSchemeTextList>
    <div class="pageBox">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="setPage"
        :total="folder.total"
        :page-size="folder.per_page"
        v-if="folder.total"
      ></el-pagination>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      :title="dialogFormTitle"
      :visible.sync="dialogFormVisible"
      width="440px"
      height="231px"
      class="dialogBox"
    >
      <el-form :model="collectForm">
        <el-form-item label="名称" label-width="50px">
          <el-input v-model="collectForm.name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer popBottom">
        <div class="popBtn1" @click="dialogFormVisible = false">
          <p>取消</p>
        </div>
        <div class="popBtn2" @click="submitCollectForm()">
          <p>确定</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import CollectSuList from "./componts/CollectSuList";
import CollectCourseList from "./componts/CollectCourseList";
import CollectMaterialList from "./componts/CollectMaterialList";
import CollectPhotoList from "./componts/CollectPhotoList";
import CollectChartletList from "./componts/CollectChartletList";

import CollectPsList from "./componts/CollectPsList";
import CollectSchemeTextList from "./componts/CollectSchemeTextList";
import CollectMaxList from "./componts/CollectMaxList";
import {
  getCollectFolder,
  addCollectFolder,
  addPhotoFolder,
  editFolder,
  editPhotoFolder,
  delfolder,
} from "../../Api";
import { getToken } from "@/utils/auth";

export default {
  name: "MyCollect",
  data() {
    return {
      activeName: "1",
      searchKey: "",
      page: 1,
      editId: null,
      folder: {},
      folderList: [
        {
          name: "",
          num: "",
          img: [],
        },
      ],
      dialogFormVisible: false,
      dialogFormTitle: "添加文件夹",
      collectForm: {
        name: "",
      },
    };
  },
  components: {
    CollectSuList,
    CollectCourseList,
    CollectMaterialList,
    CollectPhotoList,
		CollectChartletList,
    CollectPsList,
    CollectMaxList,
    CollectSchemeTextList
  },
  created() {
    this.activeName = this.$route.query.activeId
      ? this.$route.query.activeId
      : "6";
    this.page = 1;
    this.searchKey = "";
    this.handleClick();
  },
  methods: {
    setPage(val) {
      this.page = val;
      this.getCollectFolder();
    },
    delFolder(id) {
      delfolder({ fid: this.activeName, id: id, token: getToken() }).then(
        (res) => {
          if (res.status === "200" && res.data.message === "delSuccess") {
            this.getCollectFolder();
          }
        }
      );
    },
    showPopFun(data) {
      this.dialogFormVisible = true;
      this.editData = data;
      if (data) {
        this.collectForm.name = data.name;
        this.dialogFormTitle = "修改文件夹";
      } else {
        this.collectForm.name = "";
        this.dialogFormTitle = "添加文件夹";
      }
    },
    getCollectFolder() {
      const data = {
        token: getToken(),
        fid: this.activeName,
        key: this.searchKey,
        page: this.page,
        per_page: this.activeName == 2381 || this.activeName == 1196 || this.activeName == 2205 ? 9 : 11,
      };
      getCollectFolder(data).then((res) => {
        this.folderList = res.data.data;
				res.data.per_page = Number(res.data.per_page)
        this.folder = res.data;
      });
    },
    search() {
			this.page = 1;
      this.getCollectFolder();
    },
    handleClick() {
      this.searchKey = "";
      this.getCollectFolder();
    },
    //  提交添加或修改
    submitCollectForm() {
      if (this.editData) {
        // 修改
        const data = {
          token: getToken(),
          id: this.editData.id,
          name: this.collectForm.name,
        };
        if (this.activeName !== "2381") {
          // 非图库收藏夹
          editFolder(data).then((res) => {
            if (res.status === "200" && res.data.message === "editSuccess") {
              this.$message.success("修改成功");
              this.dialogFormVisible = false;
              this.getCollectFolder();
            }
          });
        } else {
          // 图库收藏夹
          editPhotoFolder(data).then((res) => {
            if (res.status === "200" && res.data.message === "editSuccess") {
              this.$message.success("修改成功");
              this.dialogFormVisible = false;
              this.getCollectFolder();
            }
          });
        }
      } else {
        //添加
        const data = {
          fid: this.activeName,
          token: getToken(),
          name: this.collectForm.name,
        };
        if (this.activeName !== "2381") {
          // 非图库收藏夹
          addCollectFolder(data).then((res) => {
            if (res.status === "200" && res.data.message === "addSuccess") {
              this.$message.success("添加成功");
              this.dialogFormVisible = false;
              this.getCollectFolder();
            }
          });
        } else {
          // 图库收藏夹
          addPhotoFolder(data).then((res) => {
            if (res.status === "200" && res.data.message === "addSuccess") {
              this.$message.success("添加成功");
              this.dialogFormVisible = false;
              this.getCollectFolder();
            }
          });
        }
      }
    },
  },
};
</script>
<style scoped>
.seleteBox >>> .el-select .el-input .el-select__caret {
  font-size: 0.14rem;
}

.seleteBox >>> .el-input__inner {
  width: 2.2rem;
  height: 30px;
  line-height: 30px;
}

.seleteBox >>> .el-date-editor .el-range-input,
.el-date-editor .el-range-separator {
  font-size: 00.14rem;
}

.seleteBox >>> .el-date-editor .el-range-separator {
  line-height: 25px;
  width: 10%;
}

.seleteBox >>> .el-date-editor .el-range__icon {
  line-height: 25px;
}

.seleteBox >>> .el-range-editor.el-input__inner {
  height: 30px;
  width: 250px;
}

.seleteBox >>> .el-tabs__header {
  margin: 0;
}

.seleteBox >>> .el-tabs__item {
  height: 00.4rem;
  line-height: 00.4rem;
}

.seleteBox >>> .el-tabs__active-bar {
  background-color: #f46600;
  bottom: 1px;
}

.seleteBox >>> .el-tabs__item.is-active {
  color: #f46600;
}

.seleteBox >>> .el-tabs__item:hover {
  color: #f46600;
  cursor: pointer;
}

.seleteBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  line-height: 0.6rem;
  height: 0.6rem;
  background: #ffffff;
  padding: 0 0.3rem;
}
.popBottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 60px;
  background: #f9f9f9;
  opacity: 1;
  border-radius: 4px;
}
.popBtn1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 32px;
  background: #eeeeee;
  opacity: 1;
  border-radius: 4px;
  margin-right: 20px;
}

.popBtn1 p {
  width: 28px;
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  opacity: 1;
}

.popBtn2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 32px;
  background: #f46600;
  opacity: 1;
  border-radius: 4px;
}

.popBtn2 p {
  width: 28px;
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  opacity: 1;
}
.dialogBox >>> .el-dialog__title {
  width: 80px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 0px;
  color: #040404;
  opacity: 1;
}
.dialogBox >>> .el-dialog__header {
  border: 1px solid #eeeeee;
}
.dialogBox >>> .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 240px;
}
.dialogBox >>> .el-dialog__body {
  padding: 30px 20px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
  padding-left: 55px;
}
.dialogBox >>> .el-dialog__footer {
  padding: 0 20px;
  text-align: right;
  box-sizing: border-box;
  background: #f9f9f9;
}
.pageBox {
  display: flex;
  justify-content: center;
  margin-top: 0.2rem;
}
</style>
<style>
</style>